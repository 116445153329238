import { AbstractControl, ValidationErrors, ValidatorFn } from "@angular/forms";

export const dateRangeValidator: ValidatorFn = (control: AbstractControl): ValidationErrors | null => {
    const from = control.get('from')?.value;
    const to = control.get('to')?.value;

    if ((from && !to) || (!from && to)) {
        return { dateRangeError: 'Both from and to dates must be filled or both must be empty' };
    }
    return null;
};
